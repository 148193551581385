import { createStore } from 'vuex';

export default createStore({

    modules: {
    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
